import React from "react";

export default function FretlinkLogo() {
  return (
    <svg
      className="fl-fretlink-logo"
      width="214"
      height="40"
      viewBox="0 0 214 40"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8 5.3a7.4 7.4 0 00-12 5.8A13.4 13.4 0 017.5 24.6a7.4 7.4 0 107.3 7.3 13.4 13.4 0 0113.5-13.5 7.4 7.4 0 004.6-13"
        fill="#288CFF"
      />
      <path
        d="M94.4 10.4a13 13 0 0113 13v4.3h-19a6 6 0 0010.9 3v-.2h7.6A12.9 12.9 0 0194.4 40a13 13 0 01-13-13v-3.7a13 13 0 0113-13zM136.3 0v39.2h-7.7v-33l6.2-6.2h1.5zm12.4 11v28.2H141V11h7.6zM193 0V21l9.9-10h9.5l-13.6 13.7 14.6 14.6h-10L193 28.8v10.5h-7.6v-33l6.1-6.2h1.5zM167 10.3a13.3 13.3 0 0113.4 13.4v15.6h-7.7V23.7a5.6 5.6 0 00-5.7-5.7 5.7 5.7 0 00-5.7 5.7v15.6h-7.6V23.7c0-7.4 6-13.4 13.3-13.4zm-87.7 0V18a5.7 5.7 0 00-5.7 5.7v15.6H66V23.7c0-7.4 6-13.4 13.4-13.4zm39-6.6v7.4h5.8v6.1h-5.7v10.6c0 2.1 1.7 3.8 3.8 3.8h1.9v7.7h-2c-6.2 0-11.4-5.2-11.4-11.5V10l6.2-6.2h1.5zM65 0v6.5h-2.8c-2.9 0-4.2 2.3-4.3 4.6H65v6.1H58v22h-7.5v-22h-4v-6.1h4C50.4 4.7 55.7 0 62.2 0H65zm29.4 17.3a6 6 0 00-5.9 4.6h11.8a6 6 0 00-5.9-4.6zM145 0a4 4 0 110 8 4 4 0 010-8z"
        fill="currentColor"
      />
    </svg>
  );
}
