import React from "react";

export function MesureIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path
            d="M20 5a15 15 0 000 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
            stroke="#E1FF1F"
          />
          <path d="M20 21a2 2 0 100 4 2 2 0 000-4z" stroke="#E1FF1F" />
          <path
            d="M30.39 26l-2.6-1.5M9.61 14l2.6 1.5M14 9.61l1.5 2.6M20 8v3m6-1.39l-1.5 2.6M9.61 26l2.6-1.5M30.39 14l-2.6 1.5M8 20h3m21 0h-3"
            stroke="#FFF"
          />
          <path d="M20 21v-8.19" stroke="#E1FF1F" />
        </g>
      </g>
    </svg>
  );
}

export function ReduceIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g transform="translate(0 7)">
          <circle cx="11" cy="24" fill="#E1FF1F" r="2" />
          <circle cx="18" cy="24" fill="#E1FF1F" r="2" />
          <circle cx="4" cy="24" fill="#E1FF1F" r="2" />
          <circle cx="33" cy="24" fill="#E1FF1F" r="2" />
          <path d="M30 6h5l4 5v8H0" stroke="#E1FF1F" strokeWidth="2" />
          <path
            d="M27 13V2a2 2 0 00-2-2H3a2 2 0 00-2 2v11a2 2 0 002 2h22a2 2 0 002-2z"
            stroke="#FFF"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

export function CompensateIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path
            d="M33 13l-6 3v2l2 2-2 1-2 7 4 4m0-24l-6 2h-3l-1-5m-4 1l3 9-3 5 4 2 2 4-3 5-5-5v-3l-4-3H5"
            stroke="#FFF"
          />
          <path
            d="M20 5a15 15 0 000 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"
            stroke="#E1FF1F"
          />
        </g>
      </g>
    </svg>
  );
}

export function DirectionsIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path d="M19.5 4v4m0 9.75V22m0 14v-4" stroke="#288CFF" />
          <path d="M30.5 18h-26L4.49 8h26l4 5z" stroke="#FFF" />
          <path d="M8.5 22h26v10h-26l-4-5z" stroke="#288CFF" />
        </g>
      </g>
    </svg>
  );
}

export function GearInABoxIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path
            d="M5 14h0c-.01-1.11.89-2.01 1.99-2.01M35 14h0a2 2 0 00-2-2M5 31h0c0 1.1.89 1.99 2 1.99M35 31h0c0 1.1-.9 2-2 2M7 12h2m22 0h2m2 2v17m-2 2H7m-2-2V14m15 0a2 2 0 100 4 2 2 0 000-4z"
            stroke="#288CFF"
          />
          <path
            d="M20 9a7 7 0 100 14 7 7 0 000-14zm-3.5 13.06l-1.5 2.6M25 7.34l-1.5 2.6m-9.56 9.56l-2.6 1.5m17.32-10l-2.6 1.5M13 16h-3m20 0h-3m-13.06-3.5l-2.6-1.5m17.32 10l-2.6-1.5M16.5 9.94L15 7.34m10 17.32l-1.5-2.6M20 9V6m0 20v-3"
            stroke="#FFF"
          />
        </g>
      </g>
    </svg>
  );
}

export function PieIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path
            d="M18 8l-.01-.001c-7.18-.019-13.01 5.81-13.01 12.99-.01 7.17 5.81 13 12.99 13 3.45 0 6.76-1.37 9.19-3.81l-9.19-9.19L18 8z"
            stroke="#288CFF"
          />
          <path
            d="M22 18l9.19 9.18c5.07-5.08 5.07-13.31-.01-18.39a13.005 13.005 0 00-9.19-3.81L22 18z"
            stroke="#FFF"
          />
        </g>
      </g>
    </svg>
  );
}

export function PathIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <path
          d="M13 19h0c0 .6-.2 1.2-.5 1.8L9 26l-3.6-5.2h0c-.3-.6-.4-1.2-.4-1.8h0a4 4 0 118 0s0 0 0 0h0z"
          stroke="#288CFF"
          strokeWidth="2"
        />
        <path d="M9 18a1 1 0 100 2 1 1 0 000-2z" fill="#288CFF" fillRule="nonzero" />
        <path
          d="M35 11h0c0 .6-.2 1.2-.5 1.8L31 18l-3.6-5.2h0c-.3-.6-.4-1.2-.4-1.8h0a4 4 0 118 0s0 0 0 0h0z"
          stroke="#288CFF"
          strokeWidth="2"
        />
        <path d="M31 10a1 1 0 100 2 1 1 0 000-2z" fill="#288CFF" fillRule="nonzero" />
        <path
          d="M12 31h21m0-4h0a2 2 0 110 4m-15-4h15m-15 0h0a2 2 0 010-4m10 0H18"
          stroke="#FFF"
          strokeWidth="2"
        />
        <path
          d="M31 22a1 1 0 100 2 1 1 0 000-2zM9 30a1 1 0 100 2 1 1 0 000-2z"
          fill="#288CFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export function MapPinIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path d="M29 14h0a9 9 0 10-18 0" stroke="#288CFF" />
          <path d="M20 11a3 3 0 100 6 3 3 0 000-6z" stroke="#FFF" />
          <path d="M11 14h0a8 8 0 001 4l8 12 8-12h0a8 8 0 001-4" stroke="#288CFF" />
          <path
            d="M14 27.3c-5.3.7-9 2-9 3.7 0 2.2 6.7 4 15 4s15-1.8 15-4c0-1.6-3.7-3-9-3.7"
            stroke="#FFF"
          />
        </g>
      </g>
    </svg>
  );
}

export function TrucksIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g transform="translate(0 7)">
          <circle cx="11" cy="24" fill="#288CFF" r="2" />
          <circle cx="18" cy="24" fill="#288CFF" r="2" />
          <circle cx="4" cy="24" fill="#288CFF" r="2" />
          <circle cx="33" cy="24" fill="#288CFF" r="2" />
          <path d="M30 6h5l4 5v8H0" stroke="#288CFF" strokeWidth="2" />
          <path
            d="M27 13V2a2 2 0 00-2-2H3a2 2 0 00-2 2v11a2 2 0 002 2h22a2 2 0 002-2z"
            stroke="#FFF"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

export function TicksIcon() {
  return (
    <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <g strokeWidth="2">
          <path d="M17.5 21.7l9.9-10m-23.7 8l7.9 7.9 2-2" stroke="#FFF" />
          <path d="M11.6 19.7l7.9 7.9 15.8-15.8" stroke="#288CFF" />
        </g>
      </g>
    </svg>
  );
}

export function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2" transform="translate(4 8)">
          <rect width="32" height="24" stroke="#288CFF" rx="4"></rect>
          <path stroke="#FFF" d="M29 8H3"></path>
          <path stroke="#288CFF" d="M29 16h-8"></path>
        </g>
      </g>
    </svg>
  );
}

export function KeyHandIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2" transform="translate(5 5)">
          <rect width="14" height="14" x="2" stroke="#FFF" rx="2"></rect>
          <path
            stroke="#FFF"
            d="M7 8a1 1 0 100 2 1 1 0 000-2zm4-4a1 1 0 100 2 1 1 0 000-2zm5 5h14V5H16"></path>
          <path
            stroke="#288CFF"
            d="M9 22h11l6-4c2-1 4-.11 4 1v1l-8 6-14 4H0v-7l5-5h11c0 2.2-1.8 4-4 4"></path>
        </g>
      </g>
    </svg>
  );
}

export function HelpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2">
          <path
            stroke="#288CFF"
            d="M23 13V5m-6 8V5m6 30v-8m-6 8v-8m10-4h8m-8-6h8M5 23h8m-8-6h8m7-12a15 15 0 100 30 15 15 0 000-30z"></path>
          <path stroke="#FFF" d="M20 13a7 7 0 100 14 7 7 0 000-14z"></path>
        </g>
      </g>
    </svg>
  );
}

export function PliersIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeLinejoin="round" strokeWidth="2">
          <path
            stroke="#FFF"
            d="M27 20.36l.56.07a7 7 0 006.79-10.37l-3.42 2.09v-.02a3 3 0 01-4.85 3.43"></path>
          <path
            stroke="#288CFF"
            d="M26.8 21.98L20.44 34l-.7.7a2 2 0 11-2.83-2.83l6.82-12.97-1.88-1.88a7 7 0 018.35-11.1l-2.1 3.4h0a3 3 0 00-3.58 4.7l1.55 1.54c1.96 1.94 1.96 4.3.73 6.42z"></path>
          <path
            stroke="#288CFF"
            d="M19.73 13.5v-.02a.99.99 0 00-1.42 0s0 0 0 0L6.29 19.85l-.7.7h0a2 2 0 102.83 2.81L20.44 17h1.41"></path>
        </g>
      </g>
    </svg>
  );
}

export function ExpandIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <path stroke="#E1FF1F" strokeWidth="2" d="M26 14l9-9"></path>
        <circle cx="20" cy="20" r="4" stroke="#FFF" strokeWidth="2"></circle>
        <path
          stroke="#E1FF1F"
          strokeWidth="2"
          d="M35 12V5h-7M14 26l-9 9m0-7v7h7m14-9l9 9m-7 0h7v-7M14 14L5 5m7 0H5v7"></path>
      </g>
    </svg>
  );
}

export function TargetIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2">
          <path stroke="#288CFF" d="M20 4a16 16 0 100 32 16 16 0 000-32z"></path>
          <path stroke="#FFF" d="M20 10a10 10 0 100 20 10 10 0 000-20z"></path>
          <path stroke="#288CFF" d="M20 16a4 4 0 100 8 4 4 0 000-8z"></path>
        </g>
      </g>
    </svg>
  );
}

export function OffersIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <rect width="32" height="8" x="4" y="4" stroke="#288CFF" strokeWidth="2" rx="1"></rect>
        <rect width="32" height="8" x="4" y="16" stroke="#FFF" strokeWidth="2" rx="1"></rect>
        <path stroke="#FFF" strokeWidth="2" d="M7 8h4M7 20h4"></path>
        <path stroke="#288CFF" strokeWidth="2" d="M13 8h20M13 20h20"></path>
        <rect width="32" height="8" x="4" y="28" stroke="#288CFF" strokeWidth="2" rx="1"></rect>
        <path stroke="#FFF" strokeWidth="2" d="M7 32h4"></path>
        <path stroke="#288CFF" strokeWidth="2" d="M13 32h20"></path>
      </g>
    </svg>
  );
}

export function GeoPointIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <path
          stroke="#E1FF1F"
          strokeWidth="2.01"
          d="M20.09 5.13V17.2m-2.01 2H5m2.02-7.04h26.13M18.08 26.23H7.02M20.08 4.12h-.01c7.54 0 13.91 5.58 14.92 13.05M20.08 4.12c3.57 0 6.5 5.7 6.97 13.07"></path>
        <path
          stroke="#E1FF1F"
          strokeWidth="2.01"
          d="M20.08 4.12h-.01a15.07 15.07 0 00-2.02 30"></path>
        <path
          stroke="#E1FF1F"
          strokeWidth="2.01"
          d="M20.08 4.12c-3.89 0-7.03 6.76-7.03 15.08 0 6.82 2.12 12.6 5.02 14.44"></path>
        <path
          stroke="#FFF"
          strokeWidth="2.01"
          d="M33 25.23c0 2.77-5.03 9.04-5.03 9.04s-5.03-6.27-5.03-9.04h0a5.01 5.01 0 1110.04-.02s0 0 0 0l.01.02z"></path>
        <path fill="#FFF" fillRule="nonzero" d="M27.97 24.22a1 1 0 100 2.01 1 1 0 000-2z"></path>
      </g>
    </svg>
  );
}

export function ValidateMesureIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2">
          <path stroke="#E1FF1F" d="M20 34.8h0a15 15 0 1115-15"></path>
          <path
            stroke="#FFF"
            d="M20 20.8a2 2 0 100 4 2 2 0 000-4zm-10.4-7l2.6 1.5M14 9.4l1.5 2.6M20 7.8v3m6-1.4L24.5 12M9.6 25.8l2.6-1.5m18.2-10.5l-2.6 1.5M8 19.8h3m21 0h-3m-9 1v-8.2"></path>
          <path stroke="#E1FF1F" d="M35 25.8l-9 9-3-3"></path>
        </g>
      </g>
    </svg>
  );
}

export function JerricanGreenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2">
          <path
            stroke="#E1FF1F"
            d="M5.4 31V13.3h0l7.9-8H31a4 4 0 014 4V31a4 4 0 01-4 4H9.4a4 4 0 01-4-4z"></path>
          <path stroke="#E1FF1F" d="M8.4 11.3l-3-3 3-3 2.9 3"></path>
          <path stroke="#FFF" strokeLinejoin="round" d="M31 9.4H15.4l-6 5.9V31h21.8z"></path>
          <path stroke="#E1FF1F" d="M24.2 24.2l-8-8m8 0l-8 8"></path>
        </g>
      </g>
    </svg>
  );
}

export const ArrowLeft24 = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.17 11.44a1 1 0 01.12-.15l4.95-4.95a1 1 0 011.42 1.42L8.4 11H19a1 1 0 110 2H8.4l3.25 3.24a1 1 0 11-1.42 1.42L5.3 12.7a1 1 0 01-.12-1.27z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export function RightArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M29.66 10.88a2 2 0 01-.25 2.53l-9.9 9.9a2 2 0 11-2.82-2.82L23.17 14H2a2 2 0 110-4h21.17L16.7 3.51A2 2 0 1119.5.7l9.9 9.9c.1.09.18.2.25.3z"></path>
    </svg>
  );
}

export const Show24 = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.05 10.27c-.96.38-1.92.89-2.88 1.53a9.52 9.52 0 005.1 2.82 3.7 3.7 0 01-2.22-4.35zm10.8 1.53a14.44 14.44 0 00-3.1-1.61 3.79 3.79 0 01-2.45 4.5 9.4 9.4 0 005.54-2.89zm2.15-.27a10.9 10.9 0 01-18 0c5.87-4.65 12.04-4.77 18 0zM10.49 9.87c-.66 0-1.19.5-1.19 1.11 0 .62.53 1.12 1.19 1.12.65 0 1.18-.5 1.18-1.12 0-.61-.53-1.11-1.18-1.11z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const Hide24 = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.95 5.5l1.41 1.42-11.3 11.32-1.43-1.41L16.95 5.5zm.48 3.77c1.2.57 2.39 1.32 3.57 2.27a10.91 10.91 0 01-10.49 4.65l5.37-5.36-.02.02a3.8 3.8 0 01-2.56 3.85 9.4 9.4 0 005.54-2.9c-.84-.57-1.7-1.03-2.54-1.4l.03-.02 1.1-1.1zM13 8.05L11.04 10a1.26 1.26 0 00-.55-.13c-.66 0-1.19.5-1.19 1.12 0 .2.06.4.17.58l-1.19 1.18a3.55 3.55 0 01-.23-2.47c-.96.37-1.92.88-2.88 1.53.69.72 1.46 1.32 2.28 1.78l-1.1 1.1A11.55 11.55 0 013 11.53c3.27-2.6 6.63-3.78 10-3.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const SuccessCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <path
      fill="#7ED321"
      fillRule="evenodd"
      d="M24 0a24 24 0 110 48 24 24 0 010-48zm8.5 14.68l-12.6 14.4-4.49-4.5a2 2 0 00-2.82 2.83l6 6a2 2 0 002.92-.1l14-16a2 2 0 10-3.02-2.63z"></path>
  </svg>
);

export const TrucksWithPalletIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" transform="translate(4 8)">
      <circle cx="3" cy="22" r="2" fill="#288CFF"></circle>
      <circle cx="10" cy="22" r="2" fill="#288CFF"></circle>
      <circle cx="25" cy="22" r="2" fill="#288CFF"></circle>
      <path stroke="#288CFF" strokeWidth="2" d="M22 5h5l4 5v7H0"></path>
      <path stroke="#FFF" strokeWidth="2" d="M20 1H0m20 12H0m20-4H0m20-4H0"></path>
    </g>
  </svg>
);

export const ThreeQuarterClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#288CFF" d="M21 35.01v-4m-16-12h4"></path>
      <path stroke="#FFF" d="M29 19.01h-8v-8"></path>
      <path stroke="#288CFF" d="M6 19.01h0a15 15 0 1114.99 14.98M6 19.01v1"></path>
      <path stroke="#FFF" strokeDasharray="1.96" d="M6.29 21.95h0A14.98 14.98 0 0019 33.87"></path>
      <path stroke="#288CFF" d="M20 34.01h1"></path>
    </g>
  </svg>
);

export const FeedbackLoopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#288CFF" d="M25 28v7h7"></path>
      <path stroke="#288CFF" strokeLinecap="round" d="M26 33.75a15 15 0 10-9.03.93"></path>
      <path stroke="#FFF" d="M14 20l4 4 8-8"></path>
    </g>
  </svg>
);

export const ReverseTicksIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" strokeWidth="1.98">
      <path stroke="#288CFF" d="M17.83 21.88L27.71 12M4 19.9l7.9 7.9 1.98-1.97"></path>
      <path stroke="#FFF" d="M11.9 19.9l7.9 7.9L35.61 12"></path>
    </g>
  </svg>
);

export const NetworkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#FFF" d="M20 11v18m12 0v-5a4 4 0 00-4-4H12a4 4 0 00-4 4v5h0"></path>
      <path stroke="#FFF" d="M32 11v5a4 4 0 01-4 4H12a4 4 0 01-4-4v-5h0"></path>
      <path
        stroke="#288CFF"
        d="M8 29a3 3 0 110 6 3 3 0 010-6zm12 0a3 3 0 110 6 3 3 0 010-6zm12 0a3 3 0 110 6 3 3 0 010-6zM8 5a3 3 0 110 6 3 3 0 010-6zm12 0a3 3 0 110 6 3 3 0 010-6zm12 0a3 3 0 110 6 3 3 0 010-6z"></path>
    </g>
  </svg>
);

export const PointTreeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path
        stroke="#288CFF"
        d="M21 12.43V36m0-.07V31.5a4.5 4.5 0 014.5-4.5 4.5 4.5 0 004.5-4.5v-1.19h0m-18 1c0 3.74 1.89 7.23 5.03 9.28L21 34.18h0"></path>
      <path
        stroke="#FFF"
        d="M12 17.31a2 2 0 100 4 2 2 0 000-4h0zm9-9a2 2 0 100 4 2 2 0 000-4h0zm9 9a2 2 0 100 4 2 2 0 000-4h0z"></path>
    </g>
  </svg>
);

export const GearLoop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <path stroke="#288CFF" d="M28 15h7V8"></path>
        <path stroke="#288CFF" d="M34 14h0a15 15 0 101 9"></path>
        <path
          stroke="#FFF"
          d="M20 16a4 4 0 100 8 4 4 0 000-8zm-3 7l-2 2m10-10l-2 2m-7 3h-3m14 0h-3m-7-3l-2-2m10 10l-2-2m-3-7v-3m0 14v-3"></path>
      </g>
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"></path>
        <g strokeWidth="2">
          <path stroke="#288CFF" d="M7 6h26l2 2v24l-2 2H7l-2-2V8l2-2z"></path>
          <path
            stroke="#FFF"
            d="M9 28h2m2 0h2m2 0h2m2 0h2M9 24h2m2 0h2m2 0h2m2 0h2m2 0h2m2 0h2M9 20h2m2 0h2m2 0h2m2 0h2m2 0h2m2 0h2m-6-4h2m2 0h2"></path>
          <path stroke="#288CFF" d="M35 10H5"></path>
        </g>
      </g>
    </svg>
  );
};

export function SuccessCircle24() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 0a12 12 0 110 24 12 12 0 010-24zm4.3 7.3l-6.4 7.2-2.2-2.2a1 1 0 10-1.4 1.4l3 3a1 1 0 001.4 0l7-8a1 1 0 00-1.4-1.4z"></path>
    </svg>
  );
}

export const Loading30 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path
      fill="currentColor"
      className="fl-rotate-forever"
      d="M30 15a15 15 0 11-30 0h3A12 12 0 1015 3V0c8 0 15 7 15 15z"
    />
  </svg>
);

export const FourberieSign = () => {
  return (
    <svg width="418" height="241" viewBox="0 0 418 241" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="0%" y1="5.55%" x2="100%" y2="5.55%" id="a">
          <stop stop-color="#D6D6D6" offset="0%"></stop>
          <stop stop-color="#D9D9D9" offset="36.3%"></stop>
          <stop stop-color="#AEAEAE" offset="70.42%"></stop>
          <stop stop-color="#DBDBDB" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0%" y1="37.46%" x2="0%" y2="100%" id="b">
          <stop stop-color="#373737" offset="0%"></stop>
          <stop stop-color="#D5D5D5" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
          <stop stop-color="#FBFBFC" offset="0%"></stop>
          <stop stop-color="#F5F6F7" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="d">
          <stop stop-color="#DDD" offset="0%"></stop>
          <stop stop-color="#C6C6C6" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="76.65%" y1="-13.43%" x2="76.65%" y2="170.85%" id="e">
          <stop stop-color="#EB3A3C" offset="0%"></stop>
          <stop stop-color="#D21B1C" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="f">
          <stop stop-color="#333" offset="0%"></stop>
          <stop stop-color="#202020" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="h">
          <stop stop-color="#FFC600" offset="0%"></stop>
          <stop stop-color="#FFB700" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="g">
          <stop stop-color="#CBCBCB" offset="0%"></stop>
          <stop stop-color="#898989" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="i">
          <stop stop-color="#FFECBF" offset="0%"></stop>
          <stop stop-color="#F0990F" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="j">
          <stop stop-color="#2B2B2B" offset="0%"></stop>
          <stop stop-color="#191919" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          d="M219.86 48.3v189.5h-27.53V48.3h27.53z"
          stroke="#9E9E9E"
          stroke-width=".5"
          fill="url(#a)"
          transform="translate(3 2)"></path>
        <path
          d="M192.58 64.55c2.76-4.67 7.26-7 13.52-7s10.76 2.33 13.51 7v-17h-27.03v17zM192.58 182.55c2.76-4.67 7.26-7 13.52-7s10.76 2.33 13.51 7v-17h-27.03v17z"
          fill="url(#b)"
          opacity=".35"
          transform="translate(3 2)"></path>
        <path
          stroke="#979797"
          stroke-width="2.5"
          d="M396 67.19c4.74 0 9.04 1.93 12.16 5.04a17.15 17.15 0 015.04 12.16h0v69.25c0 4.74-1.93 9.04-5.04 12.15a17.15 17.15 0 01-12.16 5.05h0H16.2c-4.74 0-9.04-1.93-12.16-5.05A17.15 17.15 0 01-1 153.64h0V84.39c0-4.74 1.93-9.04 5.04-12.16A17.15 17.15 0 0116.2 67.2h0z"
          fill="url(#c)"
          transform="translate(3 2)"></path>
        <path
          stroke="url(#d)"
          stroke-width="2"
          d="M396 67.44c4.67 0 8.9 1.9 11.98 4.97a16.9 16.9 0 014.97 11.98h0v69.25c0 4.67-1.9 8.9-4.97 11.98a16.9 16.9 0 01-11.98 4.97h0H16.2a16.9 16.9 0 01-11.98-4.97 16.9 16.9 0 01-4.97-11.98h0V84.39c0-4.67 1.9-8.91 4.97-11.98a16.9 16.9 0 0111.98-4.97h0z"
          transform="translate(3 2)"></path>
        <path
          d="M17 152.84h378.2V85.19H17v67.65zm379-78.65H16.2c-5.62 0-10.2 4.57-10.2 10.2v69.24c0 5.63 4.58 10.2 10.2 10.2H396c5.62 0 10.2-4.57 10.2-10.2V84.4c0-5.63-4.58-10.2-10.2-10.2z"
          fill="url(#e)"
          transform="translate(3 2)"></path>
        <path
          d="M98.47 101.45c8.25 0 12.48 7.86 12.48 17.56s-4.23 17.57-12.48 17.57c-8.26 0-12.5-7.87-12.5-17.57s4.24-17.56 12.5-17.56zm49.42.22v22.72c0 8.04-6.46 11.97-11.48 11.97a11.63 11.63 0 01-11.49-11.97l6.46-.01c0 4.35 3.28 5.32 5.03 5.32 1.75 0 5.02-.97 5.02-5.32v-22.71h6.46zm175.39.21v34.26h-6.73v-34.26h6.73zm35.5 0v6.28h-13.14v7.21h10.08v6.28h-10.08v8.21h14.09v6.28H338.9v-34.26h19.87zm-96.07 0v6.28h-13.13v7.21h10.08v6.28h-10.08v8.21h14.08v6.28h-20.81v-34.26h19.86zm-190.37 0v6.28H59.2v8.21h10.07v6.28H59.21v13.49h-6.74v-34.26h19.87zm104.84 0c8.13 0 11.16 6.72 11.16 11.94 0 4.23-2.08 7.69-5.85 10l5.62 12.32h-7.4l-4.72-10.35h-4.14v10.35h-6.74v-34.26zm114.04 0c8.13 0 11.16 6.72 11.16 11.94 0 4.23-2.08 7.69-5.85 10l5.62 12.32h-7.4l-4.72-10.35h-4.15v10.35h-6.73v-34.26zm-74.33 0a8.98 8.98 0 018.98 9.16c0 2.68-.96 5.2-2.73 7.09a8.7 8.7 0 014.2 7.6c0 6.97-5.58 10.41-12.37 10.41h-10.32v-34.26zm-1 19.54c-.3.02-.6.03-.92.03h-3.59v8.41h4.51c2.5 0 4.83-1.83 4.83-4.43 0-2.6-2.44-3.98-4.83-3.98zM98.47 108.16c-4.51 0-6.06 4.86-6.06 10.85 0 6 1.55 10.85 6.06 10.85 4.5 0 6.05-4.86 6.05-10.85 0-5.99-1.55-10.85-6.05-10.85zm32.91-6.5v22.72h-6.46v-22.71h6.46zm44.16 6.5h-3.7v11.05h3.7c4.5 0 6.03-2.43 6.03-5.54 0-3.12-1.53-5.5-6.03-5.5zm114.04 0h-3.7v11.05h3.7c4.5 0 6.02-2.43 6.02-5.54 0-3.12-1.52-5.5-6.02-5.5zm-73.69 0h-4.5v7.28h4.5c2.5 0 3.7-1.8 3.7-3.75s-1.3-3.53-3.7-3.53z"
          fill="url(#f)"
          transform="translate(3 2)"></path>
        <g>
          <path
            stroke="url(#g)"
            stroke-width="2"
            d="M166.43-1a8.5 8.5 0 018.53 8.53h0v37.74a8.5 8.5 0 01-8.53 8.53h0H7.53A8.5 8.5 0 01-1 45.27h0V7.53A8.5 8.5 0 017.53-1h0z"
            fill="url(#h)"
            transform="translate(122.54 2.25)"></path>
          <path
            stroke="url(#i)"
            stroke-width="1.5"
            d="M166.43-.75a8.25 8.25 0 018.28 8.28h0v37.74a8.25 8.25 0 01-8.28 8.28h0H7.53a8.25 8.25 0 01-8.28-8.28h0V7.53A8.25 8.25 0 017.53-.75h0z"
            transform="translate(122.54 2.25)"></path>
          <path
            d="M109.65 8.77c8.71 0 12 6.3 12 17.63 0 11.33-3.29 17.63-12 17.63-8.72 0-12.01-6.3-12.01-17.63 0-11.33 3.3-17.63 12-17.63zm-80.85.5c5.73 0 12.46 4.28 12.46 13.78v6.7c0 9.5-6.73 13.78-12.46 13.78H17.75V9.27zm54.97 0v21.06h3.45v6.28h-3.45v6.92h-6.73v-6.92H62.4v-6.28L77.04 9.27h6.73zm69.92 0v21.06h3.44v6.28h-3.44v6.92h-6.74v-6.92h-14.64v-6.28l14.64-21.06h6.74zM28.8 15.55h-4.32v21.7h4.32c2.86 0 5.73-1.9 5.73-6.1v-9.5c0-3.84-3.05-6.1-5.73-6.1zm80.85.28c-4.57 0-5.5 3.15-5.5 10.57v.37c.02 7.15 1 10.2 5.5 10.2 4.57 0 5.5-3.15 5.5-10.57s-.93-10.57-5.5-10.57zm-32.6 4.87l-6.7 9.63h6.7V20.7zm69.9 0l-6.7 9.63h6.7V20.7z"
            fill="url(#j)"
            transform="translate(122.54 2.25)"></path>
        </g>
      </g>
    </svg>
  );
};

export const ReduceEmissions40 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z"></path>
      <g stroke="#288CFF" strokeWidth="2" transform="translate(4 4)">
        <path
          strokeLinejoin="round"
          d="M16.3 24c1.6-9.3-2.1-12.4-7.7-14h0a10.9 10.9 0 006.8 13.8l.8.2h0c5-1 8.5-5.8 7.7-11-2.7.6-4.6 1.6-7.7 4.7"></path>
        <circle cx="16" cy="16" r="16"></circle>
      </g>
    </g>
  </svg>
);

export const PalletExchangeSelected24 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 18v3h-3v-1h-4v1h-4v-1H6v1H3v-3h18zM17 4c.5 0 1 .4 1 .9V10h.9a1 1 0 01.8 1.5v.1l-3 3.5a1 1 0 01-1.4 0l-3-3.5A1 1 0 0113 10h1V5c0-.5.4-1 .9-1H17zm-8.4-.2l.2.1 2.8 3.5A1 1 0 0111 9h-1v5c0 .6-.4 1-1 1H7a1 1 0 01-1-1V9h-.9a1 1 0 01-.7-1.6l2.8-3.5a1 1 0 011.4-.1z"></path>
  </svg>
);

export const SignIn24 = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 10V7.7a1 1 0 011.76-.65l3.68 4.3a1 1 0 010 1.3l-3.68 4.3A1 1 0 0118 16.3V14h-5v-4h5zm-5 0H7a2 2 0 100 4h6v2a4 4 0 01-4 4H4a4 4 0 01-4-4V8a4 4 0 014-4h5a4 4 0 014 4v2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const Menu24 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16M4 18h16"
      stroke="currentColor"
    />
  </svg>
);

export const Close24 = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 10.59L9.17 7.76a1 1 0 00-1.41 1.41L10.59 12l-2.83 2.83a1 1 0 001.41 1.41L12 13.41l2.83 2.83a1 1 0 001.41-1.41L13.41 12l2.83-2.83a1 1 0 10-1.41-1.41L12 10.59zM12 24a12 12 0 110-24 12 12 0 010 24z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
